import React, { Component } from "react";

class SharethisComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: this.props.url,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      let buttons = document
        .querySelectorAll(".sharethis-inline-share-buttons")[0]
        .querySelectorAll(".st-btn");
      buttons.forEach((element) => {
        element.style.backgroundColor = this.state.bg;
      });
    }, 500);
  }

  render() {
    return (
      <div
        className="sharethis-inline-share-buttons"
        data-url={this.state.url}
      ></div>
    );
  }
}

export default SharethisComponent;
